var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _c(
          "div",
          {
            staticStyle: { "overflow-x": "hidden" },
            attrs: { id: "body-content-area", submit: _vm.validateAndSubmit },
          },
          [
            _vm.isLoading
              ? _c("div", [_c("Loading")], 1)
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: { "padding-top": "20px" },
                      attrs: { id: "buttongroup" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "field is-grouped",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end !important",
                            gap: "1rem",
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button is-accent has-text-white",
                              attrs: {
                                disabled:
                                  !_vm.$hasPermissions(
                                    _vm.clientSession,
                                    ["ANNUAL_QUESTIONNAIRE"],
                                    2
                                  ) ||
                                  (_vm.selectedCarrierHash ===
                                    _vm.tracking.carrierHashOnLoad &&
                                    _vm.selectedVendorHash ===
                                      _vm.tracking.vendorHashOnLoad &&
                                    _vm.rates === _vm.tracking.rates &&
                                    _vm.carrierRatingSourceHash ===
                                      _vm.tracking
                                        .carrierRatingSourceHashOnLoad),
                                type: "submit",
                              },
                              on: { click: _vm.validateAndSubmit },
                            },
                            [_vm._v(" Save ")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "button is-light",
                              on: {
                                click: function ($event) {
                                  _vm.resetForm()
                                  _vm.$router.go(-1)
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { attrs: { id: "pagelayout" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "columns",
                        staticStyle: { "flex-wrap": "wrap" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "column",
                            staticStyle: { "min-width": "100%" },
                          },
                          [
                            _vm.errors && _vm.errors.length
                              ? _c(
                                  "div",
                                  _vm._l(_vm.errors, function (error) {
                                    return _c(
                                      "div",
                                      {
                                        key: error,
                                        staticStyle: { color: "red" },
                                      },
                                      [_vm._v(" " + _vm._s(error) + " ")]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "column",
                            staticStyle: { "min-width": "100%" },
                          },
                          [
                            _c("div", { staticClass: "columns" }, [
                              _c("div", { staticClass: "column" }, [
                                _vm._m(0),
                                _c(
                                  "div",
                                  {
                                    staticClass: "field",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "label is-size-7" },
                                      [_vm._v("Annual Rate")]
                                    ),
                                    _c("div", { staticClass: "control" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.rates,
                                            expression: "rates",
                                          },
                                        ],
                                        staticClass: "input",
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.rates },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.RatesChanged($event)
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.rates = $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "column" }, [
                                _vm._m(1),
                                _c(
                                  "div",
                                  {
                                    staticClass: "field",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "label is-size-7" },
                                      [_vm._v("Carrier Rating Source")]
                                    ),
                                    _c("div", { staticClass: "control" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.carrierRatingSource,
                                            expression: "carrierRatingSource",
                                          },
                                        ],
                                        staticClass: "input",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.carrierRatingSource,
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.RatingSourceChanged(
                                              $event
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.carrierRatingSource =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._m(2),
                            _c("div", { staticClass: "block" }, [
                              _c("div", { staticClass: "level" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-left",
                                    staticStyle: { width: "45%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "label is-size-7" },
                                          [_vm._v("Available Documents")]
                                        ),
                                        _c("div", { staticClass: "control" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "select is-multiple",
                                              staticStyle: { width: "100%" },
                                            },
                                            [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .selectedCarrierLeft,
                                                      expression:
                                                        "input.selectedCarrierLeft",
                                                    },
                                                  ],
                                                  ref: "carrierSelectFrom",
                                                  staticStyle: {
                                                    width: "100%",
                                                    height: "250px !important",
                                                  },
                                                  attrs: {
                                                    name: "carrierSelect",
                                                    multiple: "",
                                                    size: _vm.availableCarrier
                                                      .length,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.input,
                                                        "selectedCarrierLeft",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.sort(
                                                    _vm.availableCarrierList
                                                  ),
                                                  function (carrier) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: carrier.code,
                                                        domProps: {
                                                          value: carrier.code,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              carrier.code
                                                            ) +
                                                            " - " +
                                                            _vm._s(
                                                              carrier.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-center",
                                    staticStyle: { width: "10%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "has-text-centered",
                                        attrs: { id: "buttonblock" },
                                      },
                                      [
                                        _c("div", { staticClass: "block" }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "button is-accent is-outlined",
                                              on: {
                                                click: _vm.moveCarrierRight,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-caret-right fa-2x",
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _c("div", { staticClass: "block" }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "button is-accent is-outlined",
                                              on: {
                                                click: _vm.moveCarrierLeft,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-caret-left fa-2x",
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-right",
                                    staticStyle: { width: "45%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _vm._m(3),
                                        _c("div", { staticClass: "control" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "select is-multiple",
                                              staticStyle: { width: "100%" },
                                            },
                                            [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .selectedCarrierRight,
                                                      expression:
                                                        "input.selectedCarrierRight",
                                                    },
                                                  ],
                                                  ref: "carrierSelectTo",
                                                  staticStyle: {
                                                    width: "100%",
                                                    height: "250px !important",
                                                  },
                                                  attrs: {
                                                    multiple: "",
                                                    size: _vm.selectedCarrier
                                                      .length,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.input,
                                                        "selectedCarrierRight",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.selectedCarrier.length ===
                                                  0
                                                    ? _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Select a Carrier "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._l(
                                                    _vm.sort(
                                                      _vm.selectedCarrier
                                                    ),
                                                    function (carrier) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key:
                                                            "sel" +
                                                            carrier.code,
                                                          domProps: {
                                                            value: carrier.code,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                carrier.code
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                carrier.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._m(4),
                            _c("div", { staticClass: "block" }, [
                              _c("div", { staticClass: "level" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-left",
                                    staticStyle: { width: "45%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "label is-size-7" },
                                          [_vm._v("Available Documents")]
                                        ),
                                        _c("div", { staticClass: "control" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "select is-multiple",
                                              staticStyle: { width: "100%" },
                                            },
                                            [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .selectedVendorLeft,
                                                      expression:
                                                        "input.selectedVendorLeft",
                                                    },
                                                  ],
                                                  ref: "carrierSelectFrom",
                                                  staticStyle: {
                                                    width: "100%",
                                                    height: "250px !important",
                                                  },
                                                  attrs: {
                                                    name: "carrierSelect",
                                                    multiple: "",
                                                    size: _vm.availableVendor
                                                      .length,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.input,
                                                        "selectedVendorLeft",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.sort(_vm.availableVendor),
                                                  function (carrier) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: carrier.code,
                                                        domProps: {
                                                          value: carrier.code,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              carrier.code
                                                            ) +
                                                            " - " +
                                                            _vm._s(
                                                              carrier.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-center",
                                    staticStyle: { width: "10%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "has-text-centered",
                                        attrs: { id: "buttonblock" },
                                      },
                                      [
                                        _c("div", { staticClass: "block" }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "button is-accent is-outlined",
                                              on: {
                                                click: _vm.moveVendorRight,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-caret-right fa-2x",
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _c("div", { staticClass: "block" }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "button is-accent is-outlined",
                                              on: { click: _vm.moveVendorLeft },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-caret-left fa-2x",
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-right",
                                    staticStyle: { width: "45%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _vm._m(5),
                                        _c("div", { staticClass: "control" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "select is-multiple",
                                              staticStyle: { width: "100%" },
                                            },
                                            [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .selectedVendorRight,
                                                      expression:
                                                        "input.selectedVendorRight",
                                                    },
                                                  ],
                                                  ref: "carrierSelectTo",
                                                  staticStyle: {
                                                    width: "100%",
                                                    height: "250px !important",
                                                  },
                                                  attrs: {
                                                    multiple: "",
                                                    size: _vm.selectedVendor
                                                      .length,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.input,
                                                        "selectedVendorRight",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.selectedVendor.length ===
                                                  0
                                                    ? _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Select a Carrier "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._l(
                                                    _vm.sort(
                                                      _vm.selectedVendor
                                                    ),
                                                    function (carrier) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key:
                                                            "sel" +
                                                            carrier.code,
                                                          domProps: {
                                                            value: carrier.code,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                carrier.code
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                carrier.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [_c("span", { staticClass: "has-text-weight-bold" }, [_vm._v("Rates")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Report Info"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Carrier Due Diligence Inclusions"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v("Include in Report"),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Vendor Due Diligence Inclusions"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v("Include in Report"),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }